
import { EventSubscription } from '@/models/event-subscription';
import { TwitchHelixEndpoint } from '@/models/twitch-helix-endpoint';
import { TwitchOAuthScope } from '@/models/twitch-oauth-scope';
import baseUrl from '@/services/base-api-url';
import api$ from '@/services/tau-apis';

import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Config',
  setup() {
    const store = useStore();

    const settings = ref([
      {
        active: true,
        setting: 'Use IRC',
        description:
          'Use IRC for grabbing emote data for Channel Point Redemptions',
      },
    ]);

    const eventSubs = computed(function () {
      return store.getters['eventSubscriptions/all'];
    });

    const scopes = computed(function () {
      console.log(store.getters['twitchOAuthScopes/all']);
      return store.getters['twitchOAuthScopes/all'];
    });

    const scopeEndpoints = computed(function () {
      const endpoints = store.getters[
        'twitchHelixEndpoints/all'
      ] as TwitchHelixEndpoint[];
      const scopes = store.getters[
        'twitchOAuthScopes/all'
      ] as TwitchOAuthScope[];
      const endpointsByScope: { [key: string]: TwitchHelixEndpoint[] } = {};

      for (let scope of scopes) {
        endpointsByScope[scope.id] = endpoints.filter(
          (endpoint) => endpoint.scope === scope.id,
        );
      }
      return endpointsByScope;
    });

    async function updateEventSubs() {
      const eventSubscriptions: EventSubscription[] =
        store.getters['eventSubscriptions/all'];
      const payload = eventSubscriptions.map((es) => {
        return {
          id: es.id,
          active: es.active,
        };
      });
      await store.dispatch('eventSubscriptions/bulkActivate', payload);
      window.location.href = `${baseUrl}/refresh-token-scope/`;
    }

    async function updateTokenScopes() {
      const scopeData = scopes.value as TwitchOAuthScope[];
      const payload = scopeData.map((scope) => ({ ...scope }));
      await store.dispatch('twitchOAuthScopes/bulkUpdate', payload);
      window.location.href = `${baseUrl}/refresh-token-scope/`;
    }

    async function updateSettings() {
      console.log('update settings called!');
      for (const setting of settings.value) {
        if (setting.setting === 'Use IRC') {
          const payload = {
            value: setting.active,
          };
          const res = await api$.tau.put('settings/use_irc', payload);
        }
      }
    }

    onMounted(async () => {
      await store.dispatch('twitchOAuthScopes/loadAll');
      await store.dispatch('twitchHelixEndpoints/loadAll');
      const irc_res = await api$.tau.get('settings/use_irc');
      settings.value[0].active = irc_res.use_irc;
    });

    return {
      settings,
      eventSubs,
      scopes,
      scopeEndpoints,
      updateEventSubs,
      updateTokenScopes,
      updateSettings,
    };
  },
});
